import { BaseService } from '../SDNRequest';

const efficiency = {
	    //电费单	    
        ElecBillService :{
			
			//电费单电费数据统计
			billChargeStatics : function(orgId, dataClassifyId, year) {
				return BaseService.get('/api/efficiency/elecBill/billChargeStatics',{
						'orgId':orgId, 'dataClassifyId':dataClassifyId, 'year':year
					});
			},
			
			//电费单电力数据统计
			billElecDatas : function(orgId, dataClassifyId, year) {
				return BaseService.get('/api/efficiency/elecBill/billElecDatas',{
						'orgId':orgId, 'dataClassifyId':dataClassifyId, 'year':year
					});
			},
		},
	    //数据中心	    
        EdataInterfaceService :{
			
			//获取电表数据
			getData : function(dataAmmeterId, interval, dataCode, dt) {
				return BaseService.get('/api/edata/edataInterface/getData',{
						'dataAmmeterId':dataAmmeterId, 'interval':interval, 'dataCode':dataCode, 'dt':dt
					});
			},
			
			//获取电量数据
			getDataList : function(dataAmmeterId, interval, dataCode, beginDate, endDate, quality) {
				return BaseService.get('/api/edata/edataInterface/getDataList',{
						'dataAmmeterId':dataAmmeterId, 'interval':interval, 'dataCode':dataCode, 'beginDate':beginDate, 'endDate':endDate, 'quality':quality
					});
			},
			
			//获取实时数据
			getRealData : function(dataAmmeterId, dataCodes) {
				return BaseService.get('/api/edata/edataInterface/getRealData',{
						'dataAmmeterId':dataAmmeterId, 'dataCodes':dataCodes
					});
			},
			
			//获取电表数据项和
			getSumVal : function(dataAmmeterId, interval, dataCode, beginDt, endDt) {
				return BaseService.get('/api/edata/edataInterface/getSumVal',{
						'dataAmmeterId':dataAmmeterId, 'interval':interval, 'dataCode':dataCode, 'beginDt':beginDt, 'endDt':endDt
					});
			},
			
			//根据天气编码获得天气
			getWeatherByCode : function(weatherCode, dt) {
				return BaseService.get('/api/edata/edataInterface/getWeatherByCode',{
						'weatherCode':weatherCode, 'dt':dt
					});
			},
		},
	    //峰值分析	    
        PeakService :{
			
			//导出每日峰值数据
			exportDailyPeakData : function(orgId, dataClassifyId, fromDt, toDt) {
				return BaseService.download('/api/efficiency/peak/exportDailyPeakData',{
						'orgId':orgId, 'dataClassifyId':dataClassifyId, 'fromDt':fromDt, 'toDt':toDt
					});
			},
			
			//导出每日峰值数据
			exportDailyPeakDataByAmmeterId : function(id, dataAmmeterId, fromDt, toDt) {
				return BaseService.download('/api/efficiency/peak/exportDailyPeakDataByAmmeterId',{
						'id':id, 'dataAmmeterId':dataAmmeterId, 'fromDt':fromDt, 'toDt':toDt
					});
			},
			
			//导出峰值次数数据
			exportPeakTimesData : function(orgId, dataClassifyId, fromDt, toDt) {
				return BaseService.download('/api/efficiency/peak/exportPeakTimesData',{
						'orgId':orgId, 'dataClassifyId':dataClassifyId, 'fromDt':fromDt, 'toDt':toDt
					});
			},
			
			//每日功率峰值分布散点图
			getPeakDistribution : function(orgId, dataClassifyId, fromDt, toDt) {
				return BaseService.get('/api/efficiency/peak/getPeakDistribution',{
						'orgId':orgId, 'dataClassifyId':dataClassifyId, 'fromDt':fromDt, 'toDt':toDt
					});
			},
			
			//每日功率峰值分布散点图
			getPeakDistributionByAmmeterId : function(dataAmmeterId, fromDt, toDt) {
				return BaseService.get('/api/efficiency/peak/getPeakDistributionByAmmeterId',{
						'dataAmmeterId':dataAmmeterId, 'fromDt':fromDt, 'toDt':toDt
					});
			},
			
			//每日功率峰值次数柱状图
			getPeakTimesList : function(orgId, dataClassifyId, fromDt, toDt) {
				return BaseService.get('/api/efficiency/peak/getPeakTimesList',{
						'orgId':orgId, 'dataClassifyId':dataClassifyId, 'fromDt':fromDt, 'toDt':toDt
					});
			},
			
			//每日功率峰值次数柱状图
			getPeakTimesListByAmmeterId : function(dataAmmeterId, fromDt, toDt) {
				return BaseService.get('/api/efficiency/peak/getPeakTimesListByAmmeterId',{
						'dataAmmeterId':dataAmmeterId, 'fromDt':fromDt, 'toDt':toDt
					});
			},
		},
	    //通讯管理	    
        CommunicateManageService :{
			
			//通讯管理-通讯状态
			AllEquipmentState : function(consumeId, isGateWay) {
				return BaseService.get('/api/efficiency/communicatemanage/allequipmentstate',{
						'consumeId':consumeId, 'isGateWay':isGateWay
					});
			},
			
			//查询配电房
			queryDistributionRoom : function(consumeId) {
				return BaseService.get('/api/efficiency/communicatemanage/querydistributionroom',{
						'consumeId':consumeId
					});
			},
			
			//查询配电房完整率
			queryIntegratedRate : function(consumeId, parentOrgId, dataDate) {
				return BaseService.get('/api/efficiency/communicatemanage/queryintegratedrate',{
						'consumeId':consumeId, 'parentOrgId':parentOrgId, 'dataDate':dataDate
					});
			},
			
			//查询缺失记录
			queryMissingRecord : function(dataAmmeterId, dataDate) {
				return BaseService.get('/api/efficiency/communicatemanage/querymissingrecord',{
						'dataAmmeterId':dataAmmeterId, 'dataDate':dataDate
					});
			},
		},
	    //电能质量分析	    
        QualityService :{
			
			//三相电流不平衡度-不合格统计
			balanceOver : function(orgId, dateStart, dateEnd, pageSize, current) {
				return BaseService.get('/api/efficiency/quality/balanceOver',{
						'orgId':orgId, 'dateStart':dateStart, 'dateEnd':dateEnd, 'pageSize':pageSize, 'current':current
					});
			},
			
			//三相电流不平衡度-不合格统计
			balanceOverByAmmeterId : function(dataAmmeterId, dateStart, dateEnd, pageSize, current) {
				return BaseService.get('/api/efficiency/quality/balanceOverByAmmeterId',{
						'dataAmmeterId':dataAmmeterId, 'dateStart':dateStart, 'dateEnd':dateEnd, 'pageSize':pageSize, 'current':current
					});
			},
			
			//三相电流不平衡-合格线设置
			balanceSetStandard : function(orgId, standard) {
				return BaseService.post('/api/efficiency/quality/balanceSetStandard',{
						'orgId':orgId, 'standard':standard
					});
			},
			
			//三相电流不平衡-合格线设置
			balanceSetStandardByAmmeterId : function(dataAmmeterId, standard) {
				return BaseService.post('/api/efficiency/quality/balanceSetStandardByAmmeterId',{
						'dataAmmeterId':dataAmmeterId, 'standard':standard
					});
			},
			
			//三相电流不平衡-合格率与三相不平衡度均值趋势
			balanceStandard : function(orgId, dateStart, dateEnd) {
				return BaseService.get('/api/efficiency/quality/balanceStandard',{
						'orgId':orgId, 'dateStart':dateStart, 'dateEnd':dateEnd
					});
			},
			
			//三相电流不平衡-合格率与三相不平衡度均值趋势
			balanceStandardByAmmeterId : function(dataAmmeterId, dateStart, dateEnd) {
				return BaseService.get('/api/efficiency/quality/balanceStandardByAmmeterId',{
						'dataAmmeterId':dataAmmeterId, 'dateStart':dateStart, 'dateEnd':dateEnd
					});
			},
			
			//三相电流不平衡-日三相电流不平衡度趋势
			balanceTrend : function(orgId, date) {
				return BaseService.get('/api/efficiency/quality/balanceTrend',{
						'orgId':orgId, 'date':date
					});
			},
			
			//三相电流不平衡-日三相电流不平衡度趋势
			balanceTrendByAmmeterId : function(dataAmmeterId, date) {
				return BaseService.get('/api/efficiency/quality/balanceTrendByAmmeterId',{
						'dataAmmeterId':dataAmmeterId, 'date':date
					});
			},
			
			//功率因数合格率-不合格统计
			powerOver : function(orgId, dateStart, dateEnd, pageSize, current) {
				return BaseService.get('/api/efficiency/quality/powerOver',{
						'orgId':orgId, 'dateStart':dateStart, 'dateEnd':dateEnd, 'pageSize':pageSize, 'current':current
					});
			},
			
			//功率因数合格率-不合格统计
			powerOverByAmmeterId : function(dataAmmeterId, dateStart, dateEnd, pageSize, current) {
				return BaseService.get('/api/efficiency/quality/powerOverByAmmeterId',{
						'dataAmmeterId':dataAmmeterId, 'dateStart':dateStart, 'dateEnd':dateEnd, 'pageSize':pageSize, 'current':current
					});
			},
			
			//功率因数合格率-合格线设置
			powerSetStandard : function(orgId, standard) {
				return BaseService.post('/api/efficiency/quality/powerSetStandard',{
						'orgId':orgId, 'standard':standard
					});
			},
			
			//功率因数合格率-合格线设置
			powerSetStandardByAmmeterId : function(dataAmmeterId, standard) {
				return BaseService.post('/api/efficiency/quality/powerSetStandardByAmmeterId',{
						'dataAmmeterId':dataAmmeterId, 'standard':standard
					});
			},
			
			//功率因数合格率-合格率与功率因数均值趋势
			powerStandard : function(orgId, dateStart, dateEnd) {
				return BaseService.get('/api/efficiency/quality/powerStandard',{
						'orgId':orgId, 'dateStart':dateStart, 'dateEnd':dateEnd
					});
			},
			
			//功率因数合格率-合格率与功率因数均值趋势
			powerStandardByAmmeterId : function(dataAmmeterId, dateStart, dateEnd) {
				return BaseService.get('/api/efficiency/quality/powerStandardByAmmeterId',{
						'dataAmmeterId':dataAmmeterId, 'dateStart':dateStart, 'dateEnd':dateEnd
					});
			},
			
			//功率因数合格率-日功率因数趋势
			powerTrend : function(orgId, date) {
				return BaseService.get('/api/efficiency/quality/powerTrend',{
						'orgId':orgId, 'date':date
					});
			},
			
			//功率因数合格率-日功率因数趋势
			powerTrendByAmmeterId : function(dataAmmeterId, date) {
				return BaseService.get('/api/efficiency/quality/powerTrendByAmmeterId',{
						'dataAmmeterId':dataAmmeterId, 'date':date
					});
			},
			
			//电压合格率-超限值统计
			voltageOver : function(orgId, dateStart, dateEnd, type, pageSize, current) {
				return BaseService.get('/api/efficiency/quality/voltageOver',{
						'orgId':orgId, 'dateStart':dateStart, 'dateEnd':dateEnd, 'type':type, 'pageSize':pageSize, 'current':current
					});
			},
			
			//电压合格率-超限值统计
			voltageOverByAmmeterId : function(dataAmmeterId, dateStart, dateEnd, type, pageSize, current) {
				return BaseService.get('/api/efficiency/quality/voltageOverByAmmeterId',{
						'dataAmmeterId':dataAmmeterId, 'dateStart':dateStart, 'dateEnd':dateEnd, 'type':type, 'pageSize':pageSize, 'current':current
					});
			},
			
			//电压合格率-合格率与偏差均值趋势
			voltageStandard : function(orgId, dateStart, dateEnd) {
				return BaseService.get('/api/efficiency/quality/voltageStandard',{
						'orgId':orgId, 'dateStart':dateStart, 'dateEnd':dateEnd
					});
			},
			
			//电压合格率-合格率与偏差均值趋势
			voltageStandardByAmmeterId : function(dataAmmeterId, dateStart, dateEnd) {
				return BaseService.get('/api/efficiency/quality/voltageStandardByAmmeterId',{
						'dataAmmeterId':dataAmmeterId, 'dateStart':dateStart, 'dateEnd':dateEnd
					});
			},
			
			//电压合格率-日电压偏差值趋势
			voltageTrend : function(orgId, date) {
				return BaseService.get('/api/efficiency/quality/voltageTrend',{
						'orgId':orgId, 'date':date
					});
			},
			
			//电压合格率-日电压偏差值趋势
			voltageTrendByAmmeterId : function(dataAmmeterId, date) {
				return BaseService.get('/api/efficiency/quality/voltageTrendByAmmeterId',{
						'dataAmmeterId':dataAmmeterId, 'date':date
					});
			},
		},
	    //能耗分析	    
        EnergyAnalysisService :{
			
			//通讯管理-通讯状态
			AllEquipmentState : function(consumeId, isGateWay) {
				return BaseService.get('/api/efficiency/energyAnalysis/allequipmentstate',{
						'consumeId':consumeId, 'isGateWay':isGateWay
					});
			},
			
			//获取图表数据及统计数据
			charDatas : function(orgId, beginDate, endDate, dataClassifyId, interval) {
				return BaseService.get('/api/efficiency/energyAnalysis/charDatas',{
						'orgId':orgId, 'beginDate':beginDate, 'endDate':endDate, 'dataClassifyId':dataClassifyId, 'interval':interval
					});
			},
			
			//导出班次能耗
			exportClasscharDatas : function(orgId, beginDate, endDate, dataClassifyId, interval) {
				return BaseService.download('/api/efficiency/energyAnalysis/exportClasscharDatas',{
						'orgId':orgId, 'beginDate':beginDate, 'endDate':endDate, 'dataClassifyId':dataClassifyId, 'interval':interval
					});
			},
			
			//导出电量数据
			exportEnergyData : function(orgId, beginDate, endDate, dataClassifyId, interval, unit) {
				return BaseService.download('/api/efficiency/energyAnalysis/exportEnergyData',{
						'orgId':orgId, 'beginDate':beginDate, 'endDate':endDate, 'dataClassifyId':dataClassifyId, 'interval':interval, 'unit':unit
					});
			},
			
			//获取图表数据及统计数据
			getClasscharDatas : function(orgId, beginDate, endDate, dataClassifyId, interval) {
				return BaseService.get('/api/efficiency/energyAnalysis/getClasscharDatas',{
						'orgId':orgId, 'beginDate':beginDate, 'endDate':endDate, 'dataClassifyId':dataClassifyId, 'interval':interval
					});
			},
			
			//表格数据
			getClasstableDatas : function(pageSize, current, orgName, orgId, beginDate, endDate, dataClassifyId, interval, sortInfo) {
				return BaseService.post('/api/efficiency/energyAnalysis/getClasstableDatas',{
						'pageSize':pageSize, 'current':current, 'orgName':orgName, 'orgId':orgId, 'beginDate':beginDate, 'endDate':endDate, 'dataClassifyId':dataClassifyId, 'interval':interval, 'sortInfo':sortInfo
					});
			},
			
			//能耗总览-企业总能耗趋势
			overViewForAllEnergyTrend : function(consumeId) {
				return BaseService.get('/api/efficiency/energyAnalysis/overviewAllEnergytrend',{
						'consumeId':consumeId
					});
			},
			
			//能耗总览-企业碳排放
			overViewForCarbonEmission : function(consumeId) {
				return BaseService.get('/api/efficiency/energyAnalysis/overViewForCarbonEmission',{
						'consumeId':consumeId
					});
			},
			
			//能耗总览-分类能耗
			overViewForClassifyEnergy : function(consumeId, dataType) {
				return BaseService.get('/api/efficiency/energyAnalysis/overviewforclassifyenergy',{
						'consumeId':consumeId, 'dataType':dataType
					});
			},
			
			//能耗总览-能耗趋势
			overViewForEnergyTrend : function(consumeId, dataType) {
				return BaseService.get('/api/efficiency/energyAnalysis/overviewforenergytrend',{
						'consumeId':consumeId, 'dataType':dataType
					});
			},
			
			//能耗总览-监测设备状态
			overViewForEquipmentState : function(consumeId) {
				return BaseService.get('/api/efficiency/energyAnalysis/overviewforequipmentstate',{
						'consumeId':consumeId
					});
			},
			
			//获取饼图（子节点）数据
			pieDatas : function(orgId, beginDate, endDate, dataClassifyId, interval) {
				return BaseService.get('/api/efficiency/energyAnalysis/pieDatas',{
						'orgId':orgId, 'beginDate':beginDate, 'endDate':endDate, 'dataClassifyId':dataClassifyId, 'interval':interval
					});
			},
			
			//表格数据
			tableDatas : function(pageSize, current, orgName, orgId, beginDate, endDate, dataClassifyId, interval) {
				return BaseService.post('/api/efficiency/energyAnalysis/tableDatas',{
						'pageSize':pageSize, 'current':current, 'orgName':orgName, 'orgId':orgId, 'beginDate':beginDate, 'endDate':endDate, 'dataClassifyId':dataClassifyId, 'interval':interval
					});
			},
		},
	    //能效总览	    
        OverviewService :{
			
			//能耗设备排名
			energyDeviceTop : function(consumerId, dataType, beginDate, endDate, orderBy) {
				return BaseService.get('/api/efficiency/overview/energyDeviceTop',{
						'consumerId':consumerId, 'dataType':dataType, 'beginDate':beginDate, 'endDate':endDate, 'orderBy':orderBy
					});
			},
			
			//能耗时段排名
			energyHourTop : function(consumerId, dataType, beginDate, endDate, orderBy) {
				return BaseService.get('/api/efficiency/overview/energyHourTop',{
						'consumerId':consumerId, 'dataType':dataType, 'beginDate':beginDate, 'endDate':endDate, 'orderBy':orderBy
					});
			},
			
			//能流图
			energyTree : function(consumerId) {
				return BaseService.get('/api/efficiency/overview/energyTree',{
						'consumerId':consumerId
					});
			},
			
			//能流图
			energyTreeBydt : function(consumerId, dataType, beginDate, endDate) {
				return BaseService.get('/api/efficiency/overview/energyTreeBydt',{
						'consumerId':consumerId, 'dataType':dataType, 'beginDate':beginDate, 'endDate':endDate
					});
			},
			
			//今日测效统计
			statistics : function(dataAmmeterId) {
				return BaseService.get('/api/efficiency/overview/statistics',{
						'dataAmmeterId':dataAmmeterId
					});
			},
		},
	    //录入数据	    
        ImportDataService :{
			
			//删除录入数据表对象
			delData : function(id) {
				return BaseService.post('/api/efficiency/importData/delData',{
						'id':id
					});
			},
			
			//导入模板下载
			downloadTemplate : function() {
				return BaseService.download('/api/efficiency/importData/downloadTemplate');
			},
			
			//修改录入数据表对象
			edit : function(mapParams) {
						return BaseService.post('/api/efficiency/importData/edit',mapParams);
			},
			
			//修改录入数据表对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/efficiency/importData/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出数据
			exportFile : function(consumerId, orgId, dataType, dataClassifyId, interval, startTime, endTime) {
				return BaseService.download('/api/efficiency/importData/exportFile',{
						'consumerId':consumerId, 'orgId':orgId, 'dataType':dataType, 'dataClassifyId':dataClassifyId, 'interval':interval, 'startTime':startTime, 'endTime':endTime
					});
			},
			
			//根据ID获取录入数据表对象
			get : function(id) {
				return BaseService.get('/api/efficiency/importData/get',{
						'id':id
					});
			},
			
			//查询所有录入数据表对象
			getAll : function() {
				return BaseService.get('/api/efficiency/importData/getall');
			},
			
			//查询录入数据记录
			getImportDataAnt : function(consumerId, orgId, dataType, dataClassifyId, interval, startTime, endTime, current, pageSize) {
				return BaseService.post('/api/efficiency/importData/getImportDataAnt',{
						'consumerId':consumerId, 'orgId':orgId, 'dataType':dataType, 'dataClassifyId':dataClassifyId, 'interval':interval, 'startTime':startTime, 'endTime':endTime, 'current':current, 'pageSize':pageSize
					});
			},
			
			//导入数据
			importDataFile : function(consumerId, upFile) {
				return BaseService.upload('/api/efficiency/importData/importDataFile',{
						'consumerId':consumerId, 'upFile':upFile
					});
			},
			
			//增加录入数据表对象
			insertSingleData : function(mapParams) {
						return BaseService.post('/api/efficiency/importData/insertSingleData',mapParams);
			},
			
			//进行统计
			statistics : function(consumerId, dataType, dt, dtType) {
				return BaseService.post('/api/efficiency/importData/statistics',{
						'consumerId':consumerId, 'dataType':dataType, 'dt':dt, 'dtType':dtType
					});
			},
		},
	    //电费分析	    
        ElectricityAnalysisService :{
			
			//电量电费分析-导出自定义分析数据
			exportDataList : function(orgId, dataAmmeterId, interval, dataCode, beginDate, endDate, quality) {
				return BaseService.download('/api/efficiency/electricityAnalysis/exportDataList',{
						'orgId':orgId, 'dataAmmeterId':dataAmmeterId, 'interval':interval, 'dataCode':dataCode, 'beginDate':beginDate, 'endDate':endDate, 'quality':quality
					});
			},
			
			//导出电费电量
			exportElectricity : function(ammeterId, dataAmmeterId, interval, beginDate, endDate) {
				return BaseService.download('/api/efficiency/electricityAnalysis/exportElectricity',{
						'ammeterId':ammeterId, 'dataAmmeterId':dataAmmeterId, 'interval':interval, 'beginDate':beginDate, 'endDate':endDate
					});
			},
			
			//电量电费分析-获取今日、昨日、上周同日、上月同日电量数据
			getDetailDatasByDay : function(dataAmmeterId) {
				return BaseService.get('/api/efficiency/electricityAnalysis/getDetailDatasByDay',{
						'dataAmmeterId':dataAmmeterId
					});
			},
			
			//峰平谷统计
			getElectricOrPrice : function(ammeterId, dataAmmeterId, interval, beginDate, endDate, type) {
				return BaseService.get('/api/efficiency/electricityAnalysis/getElectricOrPrice',{
						'ammeterId':ammeterId, 'dataAmmeterId':dataAmmeterId, 'interval':interval, 'beginDate':beginDate, 'endDate':endDate, 'type':type
					});
			},
			
			//电费电量
			getElectricity : function(ammeterId, dataAmmeterId, interval, beginDate, endDate) {
				return BaseService.get('/api/efficiency/electricityAnalysis/getElectricity',{
						'ammeterId':ammeterId, 'dataAmmeterId':dataAmmeterId, 'interval':interval, 'beginDate':beginDate, 'endDate':endDate
					});
			},
			
			//电量电费-曲线趋势
			getElectricityChargeChart : function(dataAmmeterId, interval, dtTypes, types) {
				return BaseService.get('/api/efficiency/electricityAnalysis/getElectricityChargeChart',{
						'dataAmmeterId':dataAmmeterId, 'interval':interval, 'dtTypes':dtTypes, 'types':types
					});
			},
			
			//电量电费分析-电量电费统计
			getElectricityChargeStatistics : function(dataAmmeterId, dtTypes, types) {
				return BaseService.get('/api/efficiency/electricityAnalysis/getElectricityChargeStatistics',{
						'dataAmmeterId':dataAmmeterId, 'dtTypes':dtTypes, 'types':types
					});
			},
			
			//日度分析-获取电费费率时段方案
			getPeriodSchemes : function() {
				return BaseService.get('/api/efficiency/electricityAnalysis/getPeriodSchemes');
			},
		},
	    //用电负荷分析	    
        ElectricityLoadAnalysisService :{
			
			//月用电负载率分析扇形图
			getDailyElecLoadChart : function(dataAmmeterId, dateLimit) {
				return BaseService.get('/api/efficiency/electricityLoadAnalysis/getDailyElecLoadChart',{
						'dataAmmeterId':dataAmmeterId, 'dateLimit':dateLimit
					});
			},
			
			//日用电负荷或负载率分析曲线
			getDailyElecTrendDate : function(dataAmmeterId, dateLimit, dataType) {
				return BaseService.get('/api/efficiency/electricityLoadAnalysis/getDailyElecTrendDate',{
						'dataAmmeterId':dataAmmeterId, 'dateLimit':dateLimit, 'dataType':dataType
					});
			},
			
			//日用电负荷分析或负载率-表格数据
			getElectricityLoadStatistics : function(dataAmmeterId, dateLimit, dataType) {
				return BaseService.get('/api/efficiency/electricityLoadAnalysis/getElectricityLoadStatistics',{
						'dataAmmeterId':dataAmmeterId, 'dateLimit':dateLimit, 'dataType':dataType
					});
			},
			
			//月用电负载率分析曲线
			getMonthElecLoadDate : function(dataAmmeterId, dateLimit) {
				return BaseService.get('/api/efficiency/electricityLoadAnalysis/getMonthElecLoadDate',{
						'dataAmmeterId':dataAmmeterId, 'dateLimit':dateLimit
					});
			},
			
			//月用电负荷分析
			getMonthElecTrendDate : function(dataAmmeterId, dateLimit, dataType) {
				return BaseService.get('/api/efficiency/electricityLoadAnalysis/getMonthElecTrendDate',{
						'dataAmmeterId':dataAmmeterId, 'dateLimit':dateLimit, 'dataType':dataType
					});
			},
		},
	    //用电监控	    
        ElecMonitoringService :{
			
			//批量导出多条曲线 
			exportAllCurve : function(params, beginDate, endDate, interval, fileName) {
				return BaseService.download('/api/efficiency/elecMonitoring/exportAllCurve',{
						'params':params, 'beginDate':beginDate, 'endDate':endDate, 'interval':interval, 'fileName':fileName
					});
			},
			
			//导出单条平均或叠加曲线
			exportOperation : function(resourceName, dataCodes, type, dataType, ammeterIds, interval, beginDate, endDate, quality, unit, orderBy, fileName) {
				return BaseService.download('/api/efficiency/elecMonitoring/exportOperation',{
						'resourceName':resourceName, 'dataCodes':dataCodes, 'type':type, 'dataType':dataType, 'ammeterIds':ammeterIds, 'interval':interval, 'beginDate':beginDate, 'endDate':endDate, 'quality':quality, 'unit':unit, 'orderBy':orderBy, 'fileName':fileName
					});
			},
			
			//电量查询
			getAllByOrgId : function(dataCode, ammeterId, interval, beginDate, endDate, quality, orderBy) {
				return BaseService.get('/api/efficiency/elecMonitoring/getAllByOrgId',{
						'dataCode':dataCode, 'ammeterId':ammeterId, 'interval':interval, 'beginDate':beginDate, 'endDate':endDate, 'quality':quality, 'orderBy':orderBy
					});
			},
			
			//生成平均或叠加曲线
			operation : function(dataCodes, type, ammeterIds, interval, beginDate, endDate, quality, orderBy) {
				return BaseService.get('/api/efficiency/elecMonitoring/operation',{
						'dataCodes':dataCodes, 'type':type, 'ammeterIds':ammeterIds, 'interval':interval, 'beginDate':beginDate, 'endDate':endDate, 'quality':quality, 'orderBy':orderBy
					});
			},
		},
	    //需量分析	    
        QuantityService :{
			
			//实时需量监视
			liveQuantity : function(dataAmmeterId) {
				return BaseService.get('/api/efficiency/quantity/liveQuantity',{
						'dataAmmeterId':dataAmmeterId
					});
			},
			
			//最大需量与次大需量趋势
			maxAndSecondLine : function(dataAmmeterId, dateStart, dateEnd) {
				return BaseService.get('/api/efficiency/quantity/maxAndSecondLine',{
						'dataAmmeterId':dataAmmeterId, 'dateStart':dateStart, 'dateEnd':dateEnd
					});
			},
			
			//最大需量与次大需量分布散点图
			maxAndSecondPoint : function(dataAmmeterId, dateStart, dateEnd) {
				return BaseService.get('/api/efficiency/quantity/maxAndSecondPoint',{
						'dataAmmeterId':dataAmmeterId, 'dateStart':dateStart, 'dateEnd':dateEnd
					});
			},
			
			//最大需量与次大需量时间分布
			maxAndSecondTime : function(dataAmmeterId, dateStart, dateEnd) {
				return BaseService.get('/api/efficiency/quantity/maxAndSecondTime',{
						'dataAmmeterId':dataAmmeterId, 'dateStart':dateStart, 'dateEnd':dateEnd
					});
			},
			
			//超参考值次数
			maxOverCount : function(dataAmmeterId, dateStart, dateEnd) {
				return BaseService.get('/api/efficiency/quantity/maxOverCount',{
						'dataAmmeterId':dataAmmeterId, 'dateStart':dateStart, 'dateEnd':dateEnd
					});
			},
			
			//超参考值时间段分布
			maxOverTime : function(dataAmmeterId, dateStart, dateEnd) {
				return BaseService.get('/api/efficiency/quantity/maxOverTime',{
						'dataAmmeterId':dataAmmeterId, 'dateStart':dateStart, 'dateEnd':dateEnd
					});
			},
			
			//最大需量与次大需量差值趋势
			maxSecondDiff : function(dataAmmeterId, dateStart, dateEnd) {
				return BaseService.get('/api/efficiency/quantity/maxSecondDiff',{
						'dataAmmeterId':dataAmmeterId, 'dateStart':dateStart, 'dateEnd':dateEnd
					});
			},
			
			//最大需量与参考值差值趋势
			maxStandardDiff : function(dataAmmeterId, dateStart, dateEnd) {
				return BaseService.get('/api/efficiency/quantity/maxStandardDiff',{
						'dataAmmeterId':dataAmmeterId, 'dateStart':dateStart, 'dateEnd':dateEnd
					});
			},
			
			//需量详细数据
			quantityDetail : function(dataAmmeterId, current, pageSize) {
				return BaseService.get('/api/efficiency/quantity/quantityDetail',{
						'dataAmmeterId':dataAmmeterId, 'current':current, 'pageSize':pageSize
					});
			},
			
			//设置参考值
			setStandard : function(dataAmmeterId, standard) {
				return BaseService.post('/api/efficiency/quantity/setStandard',{
						'dataAmmeterId':dataAmmeterId, 'standard':standard
					});
			},
		},
	    //重点设备能耗分析	    
        ImportantAnalysisService :{
			
			//空调监测数据
			getAirCtrlDataMonitor : function(orgId, beginDate, endDate, interval) {
				return BaseService.get('/api/efficiency/importantAnalysis/getAirCtrlDataMonitor',{
						'orgId':orgId, 'beginDate':beginDate, 'endDate':endDate, 'interval':interval
					});
			},
			
			//空调效率分析
			getAirCtrlEfficiencyAnalysis : function(orgId, beginDate, endDate, interval) {
				return BaseService.get('/api/efficiency/importantAnalysis/getAirCtrlEfficiencyAnalysis',{
						'orgId':orgId, 'beginDate':beginDate, 'endDate':endDate, 'interval':interval
					});
			},
			
			//空压监测数据
			getAirZipDataMonitor : function(orgId, beginDate, endDate, interval) {
				return BaseService.get('/api/efficiency/importantAnalysis/getAirZipDataMonitor',{
						'orgId':orgId, 'beginDate':beginDate, 'endDate':endDate, 'interval':interval
					});
			},
			
			//空压系统效率分析
			getAirZipEfficiencyAnalysis : function(orgId, beginDate, endDate, interval) {
				return BaseService.get('/api/efficiency/importantAnalysis/getAirZipEfficiencyAnalysis',{
						'orgId':orgId, 'beginDate':beginDate, 'endDate':endDate, 'interval':interval
					});
			},
		},
	    //对比分析	    
        CompareService :{
			
			//导出电量对比数据
			exportCompareEnergyData : function(orgIds, consumerId, dataClassifyId, fromDt, toDt, intervalType) {
				return BaseService.download('/api/efficiency/compare/exportEnergyData',{
						'orgIds':orgIds, 'consumerId':consumerId, 'dataClassifyId':dataClassifyId, 'fromDt':fromDt, 'toDt':toDt, 'intervalType':intervalType
					});
			},
			
			//对比分析(多对象/多时间)
			getCompareAnalysis : function(dataClassifyId, orgIds, dts, interval, dataCode, type) {
				return BaseService.get('/api/efficiency/compare/getCompareAnalysis',{
						'dataClassifyId':dataClassifyId, 'orgIds':orgIds, 'dts':dts, 'interval':interval, 'dataCode':dataCode, 'type':type
					});
			},
			
			//对比分析电量曲线图
			getCompareGraphs : function(orgIds, dataClassifyId, fromDt, toDt, intervalType) {
				return BaseService.get('/api/efficiency/compare/getCompareGraphs',{
						'orgIds':orgIds, 'dataClassifyId':dataClassifyId, 'fromDt':fromDt, 'toDt':toDt, 'intervalType':intervalType
					});
			},
			
			//对比分析电量饼图
			getComparePie : function(orgIds, dataClassifyId, fromDt, toDt, intervalType) {
				return BaseService.get('/api/efficiency/compare/getComparePie',{
						'orgIds':orgIds, 'dataClassifyId':dataClassifyId, 'fromDt':fromDt, 'toDt':toDt, 'intervalType':intervalType
					});
			},
			
			//能耗详细
			getConsumptionInfo : function(orgIds, consumerId, dataClassifyId, fromDt, toDt, intervalType) {
				return BaseService.get('/api/efficiency/compare/getConsumptionInfo',{
						'orgIds':orgIds, 'consumerId':consumerId, 'dataClassifyId':dataClassifyId, 'fromDt':fromDt, 'toDt':toDt, 'intervalType':intervalType
					});
			},
		},
	    //报表统计	    
        StatementService :{
			
			//导出电量数据
			exportEnergyData : function(orgId, fromDt, toDt, intervalType, isAllChildren) {
				return BaseService.download('/api/efficiency/statement/exportEnergyData',{
						'orgId':orgId, 'fromDt':fromDt, 'toDt':toDt, 'intervalType':intervalType, 'isAllChildren':isAllChildren
					});
			},
			
			//导出台账电量数据
			exportMonitorEnergyData : function(orgId, classifiedType, fromDt, toDt, intervalType, isAllChildren) {
				return BaseService.download('/api/efficiency/statement/exportMonitorEnergyData',{
						'orgId':orgId, 'classifiedType':classifiedType, 'fromDt':fromDt, 'toDt':toDt, 'intervalType':intervalType, 'isAllChildren':isAllChildren
					});
			},
			
			//导出多个台账单元查询报表
			exportMonitorMultiReport : function(orgId, classifiedType, fromDt, toDt, intervalType, isAllChildren) {
				return BaseService.download('/api/efficiency/statement/exportMonitorMultiReport',{
						'orgId':orgId, 'classifiedType':classifiedType, 'fromDt':fromDt, 'toDt':toDt, 'intervalType':intervalType, 'isAllChildren':isAllChildren
					});
			},
			
			//导出多个管理单元查询报表
			exportMultiReport : function(orgId, fromDt, toDt, intervalType, dataClassifyId, isAllChildren) {
				return BaseService.download('/api/efficiency/statement/exportMultiReport',{
						'orgId':orgId, 'fromDt':fromDt, 'toDt':toDt, 'intervalType':intervalType, 'dataClassifyId':dataClassifyId, 'isAllChildren':isAllChildren
					});
			},
			
			//导出单个管理单元查询报表
			exportSingleReport : function(orgId, dataClassifyId, fromDt, toDt, intervalType) {
				return BaseService.download('/api/efficiency/statement/exportSingleReport',{
						'orgId':orgId, 'dataClassifyId':dataClassifyId, 'fromDt':fromDt, 'toDt':toDt, 'intervalType':intervalType
					});
			},
			
			//台账数据查询
			getMonitorData : function(orgId, classifiedType, fromDt, toDt, intervalType, isAllChildren) {
				return BaseService.get('/api/efficiency/statement/getMonitorData',{
						'orgId':orgId, 'classifiedType':classifiedType, 'fromDt':fromDt, 'toDt':toDt, 'intervalType':intervalType, 'isAllChildren':isAllChildren
					});
			},
			
			//多个管理单元查询
			getMultiOrgUnitData : function(orgId, fromDt, toDt, intervalType, dataClassifyId, isAllChildren) {
				return BaseService.get('/api/efficiency/statement/getMultiOrgUnitData',{
						'orgId':orgId, 'fromDt':fromDt, 'toDt':toDt, 'intervalType':intervalType, 'dataClassifyId':dataClassifyId, 'isAllChildren':isAllChildren
					});
			},
			
			//单个管理单元查询
			getSingleOrgUnitData : function(orgId, dataClassifyId, fromDt, toDt, intervalType) {
				return BaseService.get('/api/efficiency/statement/getSingleOrgUnitData',{
						'orgId':orgId, 'dataClassifyId':dataClassifyId, 'fromDt':fromDt, 'toDt':toDt, 'intervalType':intervalType
					});
			},
		},
 
}

export default efficiency;